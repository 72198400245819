// configStore.js
let globalConfig = null;

export const setConfig = (config) => {
    globalConfig = config;
};

export const getDebugMode = () => {
    return globalConfig?.debug_mode || false;
};

export const getConfig = () => {
    return globalConfig;
};
