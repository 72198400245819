import App from './App';
import { setConfig } from './configStore';
import {
    render,
    createContext,
} from '@wordpress/element';
import { createRoot } from 'react-dom/client';
import {
    createBrowserRouter,
    RouterProvider,
} from 'react-router-dom';
import './style/main.scss'; 
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ErrorComponent from './ErrorComponent';

export const ConfigContext = createContext(null);
export const ShortcodeContext = createContext(null);

function initializeApp() {
    try {
        const roots = document.querySelectorAll('[id^="range-headless-root-"]');
        if (!roots.length) {
            console.warn('No Range Headless root elements found');
            return;
        }

        // Initialize data structures
        const shortcodeData = {};
        let globalConfig = null;

        // First pass: collect all data and find global config
        roots.forEach((root) => {
            try {
                const rawData = root.dataset.rangeheadless;
                if (!rawData) {
                    throw new Error(`No data found for root ${root.id}`);
                }

                const parsedData = JSON.parse(rawData);
                
                // Store shortcode data
                shortcodeData[root.id] = parsedData;

                // If this shortcode has global config, store it
                if (parsedData.hasGlobal && parsedData.globalConfig) {
                    globalConfig = parsedData.globalConfig;
                }
            } catch (error) {
                Sentry.captureException(error, {
                    extra: {
                        rootId: root.id,
                        dataAttribute: root.dataset.rangeheadless
                    }
                });
            }
        });

        // If no global config was found, try to fetch it from the first shortcode
        if (!globalConfig && Object.keys(shortcodeData).length > 0) {
            const firstShortcodeData = Object.values(shortcodeData)[0];
            if (firstShortcodeData.globalConfig) {
                globalConfig = firstShortcodeData.globalConfig;
                setConfig(globalConfig);
            } else {
                console.error('No global config found in any shortcode');
                return;
            }
        } else if (globalConfig) {
            setConfig(globalConfig);
        }

        // Initialize React Query
        const queryClient = new QueryClient({
            defaultOptions: {
                queries: {
                    retry: 2,
                    staleTime: 30000,
                }
            }
        });

        // Create router
        const router = createBrowserRouter([
            {
                path: '/:viewName?/:locationSlug?/:categoryType?/:categoryName?/:specialCategoryName?',
                element: (
                    <Sentry.ErrorBoundary
                        fallback={(props) => <ErrorComponent {...props} />}
                        onError={(error) => {
                            Sentry.captureException(error, {
                                extra: {
                                    shortcodeData,
                                    globalConfig
                                }
                            });
                        }}
                    >
                        <ConfigContext.Provider value={globalConfig}>
                            <ShortcodeContext.Provider value={shortcodeData}>
                                <App 
                                    shortcodeData={shortcodeData} 
                                    globalConfig={globalConfig}
                                    fallback={rangeHeadlessApi.fallback} 
                                />
                            </ShortcodeContext.Provider>
                        </ConfigContext.Provider>
                    </Sentry.ErrorBoundary>
                ),
            },
        ]);

        // Only render to first root
        if (roots.length > 0) {
            const rootElement = roots[0];
            if (createRoot) {
                createRoot(rootElement).render(
                    <React.StrictMode>
                        <QueryClientProvider client={queryClient}>
                            <RouterProvider router={router} />
                        </QueryClientProvider>
                    </React.StrictMode>
                );
            } else {
                render(
                    <React.StrictMode>
                        <QueryClientProvider client={queryClient}>
                            <RouterProvider router={router} />
                        </QueryClientProvider>
                    </React.StrictMode>,
                    rootElement
                );
            }
        }

    } catch (error) {
        Sentry.captureException(error);
        console.error('Failed to initialize Range Headless:', error);
    }
}

// Initialize the app
initializeApp();
